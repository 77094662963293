<template>
  <aside class="bg-white c-z-position shadow">
    <div class="position-relative c-heght-sidebar">
      <div class="d-flex justify-content-between bg-success p-20">
        <h3>WARENKORB</h3>
        <button @click="toggle" class="border-0 bg-success">
          <img class="pe-20" src="@/assets/images/close.svg" />
        </button>
      </div>
      <div class="w-100 c-overflow-scoll bg-white">
        <div
          class="
            c-items-height
            d-flex
            justify-content-between
            p-20
            border-bottom
            b-3
            m-20
          "
          v-for="product in shopStore.state.cartProducts"
          :key="product.uid"
        >
          <div>
            <img class="c-product-image" :src="product.image" />
          </div>
          <div class="ms-16">
            <h3>{{ product.name }}</h3>
            <h4 class="mb-4">{{ product.category.name }}</h4>
            <h4>{{ product.description }}</h4>
            <button
              @click="minusOne(product.uid), calculateTotalPrice()"
              class="border-0 bg-white text-secondary mt-10"
            >
              -</button
            ><input
              type="number"
              min="0"
              oninput="validity.valid||(value='0');"
              class="border-0 d-inline-block text-center w-25 text-secondary"
              @change="calculateTotalPrice()"
              v-model="product.quantity"
            /><button
              @click="plusOne(product.uid), calculateTotalPrice()"
              class="border-0 bg-white text-secondary"
            >
              +
            </button>
          </div>
          <div class="d-flex justify-content-between flex-column">
            <button
              class="bg-white border-0"
              @click="removteProduct(product.uid), calculateTotalPrice()"
            >
              <img class="c-height-close" src="@/assets/images/close.svg" />
            </button>
            <h3>€ {{ (parseFloat(product.price) * product.quantity).toFixed(2) }}</h3>
          </div>
        </div>
      </div>
      <div class="c-position-absolute px-20 pb-20 w-100 bottom-0 bg-white">
        <div
          class="d-flex justify-content-between w-100 border-3 border-top p-20"
        >
          <div><strong>ZUSAMMENFASSUNG</strong></div>
          <div>
            <strong>€ {{ shopStore.state.totalPrice.toFixed(2) }}</strong>
          </div>
        </div>
        <router-link
          @click="toggle"
          class="btn btn-dark w-100 p-20"
          to="/formular"
          >CHECKOUT</router-link
        >
      </div>
    </div>
    <Footer />
  </aside>
</template>

<script lang="ts">
import { useStore } from "@/stores/store";
import { mapStores } from "pinia";

import Footer from "@/components/footer/Footer.vue";

export default {
  components: {
    Footer,
  },
  name: "Cart",
  props: ["toggle"],
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    minusOne(id: number) {
      let object = this.shopStore.state.cartProducts.find((o) => o.uid === id);
      if (!object) return;
      if (object.quantity <= 1) {
        this.removteProduct(id), this.calculateTotalPrice();
      } else {
        object.quantity = object.quantity - 1;
      }
    },
    plusOne(id: number) {
      let object = this.shopStore.state.cartProducts.find((o) => o.uid === id);
      if (!object) return;
      object.quantity = object.quantity + 1;
    },
    removteProduct(id: number) {
      let index = this.shopStore.state.cartProducts.findIndex((x) => x.uid === id);
      if (index !== -1) this.shopStore.state.cartProducts.splice(index, 1);
    },
    calculateTotalPrice() {
      this.shopStore.caluculateTotalPrice();
    },
  },
};
</script>
<style lang="scss">
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.c-z-position {
  z-index: 2;
  top: 110px;
  width: 100%;
  position: absolute;
  height: 100vh;
  bottom: 0px;
}

.c-overflow-scoll {
  overflow-y: scroll;
  min-height: 60vh;
  max-height: 60vh;
}

.c-height-close {
  height: 17px;
}

.c-items-height {
  height: 200px;
}

.c-product-image {
  object-fit: contain;
  height: 150px;
  width: 150px;
}

@media (min-width: 1100px) {
  .c-z-position {
    z-index: 2;
    top: 110px;
    bottom: 0;
    right: 0;
    width: 37%;
    height: calc(100vh - 100px);
    position: fixed;
  }

  .c-position-absolute {
    position: absolute;
    bottom: 0;
  }

  .c-heght-sidebar {
    height: 100%;
  }

  .c-overflow-scoll {
    max-height: 70vh;
  }
}
</style>
