import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { CartProduct, Category, ConfigType, JwtIdToken, Product, ShopDetails } from '@/types';

export const useStore = defineStore('shop',
  () => {
    let selectedProfile = JSON.parse(sessionStorage.getItem('profile') ?? '5');
    let selectedLoa = JSON.parse(sessionStorage.getItem('loa') ?? '0');
    const state = ref({
      config: undefined as unknown as ConfigType,
      cartOpen: false,
      profilesOpen: false,
      toggleComponents: true,
      heroOnFilter: true,
      cartProducts: [] as CartProduct[],
      totalPrice: 0,
      products: [] as (Product & {category: Category, category_id: number})[],
      shopDetails: {} as ShopDetails['details'],
      productCategory: [] as Category[],
      filteredProducts: [] as (Product & {category: Category, category_id: number})[],
      test: true,
      activeCategory: 'all' as 'all' | number,
      selectedProfile,
      selectedLoa,
      ageVerificationFailed: false,
      ageVerified: false,
      ichAuthSuccess: false,
      usersub: '',
      token: '',
      userData: {} as JwtIdToken,
      profiles: [
        { profile: 'Altersverifikation18Y', fields: ['urn:ich.app:AgeVerification18:Data'] },
        { profile: 'ichIDOnly', fields: [] },
        { profile: 'DataSetL', fields: ['urn:ich.app:Title:Data', 'urn:ich.app:Firstname:Data', 'urn:ich.app:Lastname:Data', 'urn:ich.app:DateofBirth:Data', 'urn:ich.app:Phonenumber:Data', 'urn:ich.app:Mail:Data', 'urn:ich.app:Address:Data', 'urn:ich.app:Gender:Data'] },
        { profile: 'DataSetM', fields: ['urn:ich.app:Mail:Data', 'urn:ich.app:Title:Data', 'urn:ich.app:Firstname:Data', 'urn:ich.app:Lastname:Data', 'urn:ich.app:Phonenumber:Data', 'urn:ich.app:Address:Data', 'urn:ich.app:Gender:Data'] },
        { profile: 'DataSetS', fields: ['urn:ich.app:Title:Data', 'urn:ich.app:Firstname:Data', 'urn:ich.app:Lastname:Data', 'urn:ich.app:DateofBirth:Data', 'urn:ich.app:Gender:Data'] },
        { profile: 'DataSetXL', fields: ['urn:ich.app:Title:Data', 'urn:ich.app:Firstname:Data', 'urn:ich.app:Lastname:Data', 'urn:ich.app:DateofBirth:Data', 'urn:ich.app:Phonenumber:Data', 'urn:ich.app:Mail:Data', 'urn:ich.app:Address:Data', 'urn:ich.app:Nationality:Data', 'urn:ich.app:Gender:Data', 'urn:ich.app:IBAN:Data'] },
        { profile: 'DataSetXS', fields: ['urn:ich.app:Mail:Data', 'urn:ich.app:Title:Data', 'urn:ich.app:Firstname:Data', 'urn:ich.app:Lastname:Data', 'urn:ich.app:Gender:Data'] },
        { profile: 'DataSetXXS', fields: ['urn:ich.app:Mail:Data'] }
      ] as const
    }
  )

  function allProducts() {
    state.value.activeCategory = 'all';
    state.value.filteredProducts = [...state.value.products];
  }

  function onFiltered() {
    state.value.heroOnFilter = false;
  }

  function onNotFiltered() {
    state.value.heroOnFilter = true;
  }

  function openProfiles() {
    state.value.profilesOpen = true;
  }

  function closeProfiles() {
    state.value.profilesOpen = false;
  }

  function openCart() {
    state.value.cartOpen = true;
    if (screen.width <= 1100) {
      state.value.toggleComponents = false;
    }
  }

  function closeCart() {
    state.value.cartOpen = false;
    state.value.toggleComponents = true;
  }

  function clearCart() {
    state.value.cartProducts = [];
    state.value.totalPrice = 0;
    sessionStorage.setItem('products', JSON.stringify(state.value.cartProducts));
  }

  function setSessionStorage() {
    sessionStorage.setItem('products', JSON.stringify(state.value.cartProducts));
  }

  function setSelectedProfileStorage() {
    sessionStorage.setItem('profile', JSON.stringify(state.value.selectedProfile));
  }

  function setSelectedLoaStorage() {
    sessionStorage.setItem('loa', JSON.stringify(state.value.selectedLoa));
  }

  function caluculateTotalPrice() {
    let price = 0;
    for (let i = 0; i < state.value.cartProducts.length; i++) {
      price += parseFloat(state.value.cartProducts[i].price) * state.value.cartProducts[i].quantity;
    }
    state.value.totalPrice = price;
  }

  function productsFilter(id: number) {
    state.value.activeCategory = id;
    state.value.filteredProducts = state.value.products.filter(products => products.category_id === id);
    window.scrollTo(0, 0);
  }

  return {
    state,
    allProducts,
    onFiltered,
    onNotFiltered,
    openProfiles,
    closeProfiles,
    openCart,
    closeCart,
    clearCart,
    setSessionStorage,
    setSelectedProfileStorage,
    setSelectedLoaStorage,
    caluculateTotalPrice,
    productsFilter,
  }
})
