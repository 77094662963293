<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4 text-center mt-40">
        <img class="mb-20" src="@/assets/images/shipping.svg" />
        <h3>Schnelle und kostenlose Lieferung</h3>
        <h4>Kostenlose Lieferung für alle Bestellungen über 140€</h4>
      </div>
      <div class="col-xl-4 text-center mt-40">
        <img class="mb-20" src="@/assets/images/headphone.svg" />
        <h3>Kundenbetreuung</h3>
        <h4>Freundlicher 24/7-Kundensupport</h4>
      </div>
      <div class="col-xl-4 text-center mt-40">
        <img class="mb-20" src="@/assets/images/shield.svg" />
        <h3>Geld-zurück-Garantie</h3>
        <h4>Wir erstatten das Geld innerhalb von 30 Tagen</h4>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: 'References'
};
</script>
