<template>
  <div class="container mt-40">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-9">
        <div class="col-12 d-lg-flex align-items-center mb-20" v-if="shopStore.state.selectedProfile > 1">
          <h2>Meine Daten</h2>
          <div v-if="Object.keys(shopStore.state.userData).length === 0" class="c-ich-border-form ms-lg-20">
            <button @click="redirectToIch()" class="border-0 bg-white d-flex align-items-center c-button-ich">
              <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
              <p>Ausfüllen mit ich.</p>
            </button>
          </div>
          <div v-else class="d-inline-block ms-lg-20 c-loggin-ichborder">
            <button class="border-0 bg-white c-ich-color c-button-ich">
              <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
              Ausgefüllt mit ich
              <img class="c-shild-heigth c-shield-icon" src="@/assets/images/ich-shield.svg" />
            </button>
          </div>
        </div>
        <div class="col-12 mb-50" v-if="shopStore.state.selectedProfile === 0">
          <h2>Altersverifikation</h2>
        </div>
        <div class="col-12 mb-50" v-if="shopStore.state.selectedProfile === 1">
          <h2>Mit ich authentifizieren.</h2>
        </div>
        <div class="col-12 d-lg-flex align-items-center mb-20" v-if="shopStore.state.selectedProfile === 0 && !shopStore.state.ageVerified">
          <div class="d-inline-block c-loggin-ichborder">
            <button @click="redirectToIch()" class="border-0 bg-white c-ich-color c-button-ich">
              <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
              Alter verifizieren mit ich.
            </button>
          </div>
        </div>
        <div class="col-12 d-lg-flex align-items-center mb-20 message error" v-if="shopStore.state.ageVerificationFailed">
          Altersverifikation fehlgeschlagen. Der User ist nicht über {{shopStore.state.profiles[shopStore.state.selectedProfile].fields[0]!.split('').filter(n => Number.isInteger(parseInt(n)) ? true : false).join('')}}  Jahre
        </div>
        <div class="col-12 d-lg-flex align-items-center mb-20 message" v-if="!shopStore.state.ageVerificationFailed && shopStore.state.ageVerified">
          Altersverifikation erfolgreich. Der User ist über {{shopStore.state.profiles[shopStore.state.selectedProfile].fields[0]!.split('').filter(n => Number.isInteger(parseInt(n)) ? true : false).join('')}} Jahre
        </div>
        <div class="col-12 d-lg-flex align-items-center mb-20" v-if="shopStore.state.selectedProfile === 1">
          <div class="message" v-if="shopStore.state.ichAuthSuccess">
            <span>Anmeldung mit ich erfolgreich!</span>
          </div>
          <div v-else>
            <div class="d-inline-block c-loggin-ichborder">
              <button @click="redirectToIch()" class="border-0 bg-white c-ich-color c-button-ich">
                <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
                Mit ich authentifizieren.
              </button>
            </div>
          </div>
        </div>
        <div v-if="Number.isInteger(parseInt(shopStore.state.selectedProfile))" >
          <div class="row">
            <div :class="{'col-md-6': field !== 'urn:ich.app:Address:Data'}" class="col-12 my-10" v-for="field in shopStore.state.profiles[shopStore.state.selectedProfile].fields">
              <input
                    class="p-20 w-100"
                    type="text"
                    placeholder="Titel"
                    :value="shopStore.state.userData[field]"
                    id="title"
                    required
                    v-if="field === 'urn:ich.app:Title:Data'"
                  />
              <input
                    class="p-20 w-100"
                    type="text"
                    placeholder="Vorname"
                    id="firstname"
                    required
                    v-if="field === 'urn:ich.app:Firstname:Data'"
                    v-model="firstname"
                  />
              <input
                  class="p-20 w-100"
                  type="text"
                  placeholder="Nachname"
                  id="lastname"
                  required
                  v-if="field === 'urn:ich.app:Lastname:Data'"
                  v-model="lastname"
                />
              <input
                  class="p-20 w-100"
                  type="email"
                  placeholder="E-Mail Adresse"
                  id="email"
                  required
                  v-if="field === 'urn:ich.app:Mail:Data'"
                  v-model="mail"
                />
                <input
                    class="p-20 w-100"
                    type="phone"
                    placeholder="Handynummer"
                    id="phone"
                    v-if="field === 'urn:ich.app:Phonenumber:Data'"
                    v-model="phone"
                />
                <div class="address" v-if="field === 'urn:ich.app:Address:Data'">
                  <input
                    class="p-20 w-100"
                    type="text"
                    placeholder="Straße Nr."
                    id="street"
                    required
                    v-if="field === 'urn:ich.app:Address:Data'"
                    v-model="street"
                  />
                  <input
                      class="p-20 w-100"
                      type="text"
                      placeholder="Stiege"
                      id="stair"
                      v-if="field === 'urn:ich.app:Address:Data'"
                      v-model="stair"
                  />
                  <input
                      class="p-20 w-100"
                      type="number"
                      placeholder="Tür"
                      id="door"
                      v-if="field === 'urn:ich.app:Address:Data'"
                      v-model="door"
                  />
                  <input
                      class="p-20 w-100"
                      type="number"
                      placeholder="PLZ"
                      id="plz"
                      required
                      v-if="field === 'urn:ich.app:Address:Data'"
                      v-model="postcode"
                  />
                  <input
                      class="p-20 w-100"
                      type="text"
                      placeholder="Ort"
                      id="place"
                      required
                      v-if="field === 'urn:ich.app:Address:Data'"
                      v-model="place"
                  />
                </div>
                <input
                    class="p-20 w-100"
                    type="date"
                    placeholder="Geburtstag"
                    :value="shopStore.state.userData[field]"
                    id="date"
                    required
                    v-if="field === 'urn:ich.app:DateofBirth:Data'"
                  />
                <input
                  class="p-20 w-100"
                  type="text"
                  placeholder="Geschlecht"
                  :value="shopStore.state.userData[field]"
                  id="gender"
                  required
                  v-if="field === 'urn:ich.app:Gender:Data'"
                />
                <input
                  class="p-20 w-100"
                  type="text"
                  placeholder="Nationalität"
                  :value="shopStore.state.userData[field]"
                  id="nationality"
                  required
                  v-if="field === 'urn:ich.app:Nationality:Data'"
                />
                <input
                  class="p-20 w-100"
                  type="text"
                  placeholder="IBAN"
                  :value="shopStore.state.userData[field]"
                  id="iban"
                  required
                  v-if="field === 'urn:ich.app:IBAN:Data'"
                />
            </div>
          </div>
          <div class="col-12 my-10" v-if="shopStore.state.selectedProfile > 1">
            <textarea class="w-100 p-20" placeholder="Liefernotiz (max 240 Zeichen)" id="node"></textarea>
          </div>
          <div class="col-12" v-if="shopStore.state.selectedProfile > 1">
            <form @submit.prevent="saveData()">
                <div class="row">
                  <div class="col-12">
                    <button class="btn btn-dark w-100 my-35 py-20 c-max-width" type="submit">WEITER ZUR ÜBERSICHT</button>
                  </div>
              </div>
            </form>
          </div>
        </div>
        <div v-else>
          <p>Bitte wählen Sie ein Profil aus.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { useStore } from '@/stores/store';
import { mapStores } from 'pinia';

export default {
  name: 'Formular',
  data() {
    return {
      firstname: useStore().state.userData['urn:ich.app:Firstname:Data'],
      lastname: useStore().state.userData['urn:ich.app:Lastname:Data'],
      mail: useStore().state.userData['urn:ich.app:Mail:Data'],
      phone: useStore().state.userData['urn:ich.app:Phonenumber:Data'],
      street: useStore().state.userData['urn:ich.app:Address:Data'] ? useStore().state.userData['urn:ich.app:Address:Data'].thoroughfare + ' ' + useStore().state.userData['urn:ich.app:Address:Data'].locatorDesignator : '',
      stair: useStore().state.userData['urn:ich.app:Address:Data'] ? useStore().state.userData['urn:ich.app:Address:Data'].adminUnitL1 : '',
      door: useStore().state.userData['urn:ich.app:Address:Data'] ? useStore().state.userData['urn:ich.app:Address:Data'].adminUnitL2 : '',
      postcode: useStore().state.userData['urn:ich.app:Address:Data'] ? useStore().state.userData['urn:ich.app:Address:Data'].postCode : '',
      place: useStore().state.userData['urn:ich.app:Address:Data'] ? useStore().state.userData['urn:ich.app:Address:Data'].postName : ''
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    redirectToIch() {
      const instance = this.shopStore.state.config.instance;
      const apiHost = this.shopStore.state.config.apiHost;
      const currentLocation = window.location.hash === '#/' ? 'home' : 'formular';
      this.$cookies.set('ichapp-last-visited', currentLocation);
      let url = apiHost + 'auth_get_token';
      let profileIndex = this.shopStore.state.selectedProfile
      let loa = this.shopStore.state.selectedLoa;
      url += Number.isInteger(profileIndex) ? '?profile=' + this.shopStore.state.profiles[profileIndex].profile : '';
      url += Number.isInteger(parseInt(loa)) ? '&loa=' + loa : ''
      url += '&version=' + instance;
      location.href = url;
    },
    saveData() {
      if (this.shopStore.state.selectedProfile > 1) {
        this.shopStore.state.userData['urn:ich.app:Firstname:Data'] = this.firstname;
        this.shopStore.state.userData['urn:ich.app:Lastname:Data'] = this.lastname;
        this.shopStore.state.userData['urn:ich.app:Mail:Data'] = this.mail;
        this.shopStore.state.userData['urn:ich.app:Phonenumber:Data'] = this.phone;
        this.shopStore.state.userData['urn:ich.app:Address:Data'] = {};
        this.shopStore.state.userData['urn:ich.app:Address:Data'].thoroughfare = this.street;
        this.shopStore.state.userData['urn:ich.app:Address:Data'].adminUnitL1 = this.stair;
        this.shopStore.state.userData['urn:ich.app:Address:Data'].locatorDesignator = this.door;
        this.shopStore.state.userData['urn:ich.app:Address:Data'].postCode = this.postcode;
        this.shopStore.state.userData['urn:ich.app:Address:Data'].postName = this.place;
        this.$router.push('/invoice');
      } else {
        if (this.shopStore.state.ageVerified) {
          this.$router.push('/invoice');
        }
      }
    }
  }
};
</script>
<style lang="scss">
.c-max-width {
  max-width: 370px;
}

.c-ich-color {
  color: #00b383;
}

.c-loggin-ichborder {
  border: 1px solid #51f0b0;
  border-radius: 25px;
  padding: 5px;
  padding-right: 15px;
  margin-top: -8px;

  .c-shild-heigth {
    height: 25px;
  }
}

.address {
  display: flex;

  #street {
    flex: 3;
    margin-right: 20px;
  }

  #stair {
    flex: 1;
  }

  #door {
    flex: 1;
    margin-left: 20px;
  }

  #plz {
    flex: 1;
    margin-left: 20px;
  }

  #place {
    flex: 3;
    margin-left: 20px;
  }

}

.c-ich-border-form {
  border: solid #51f0b0;
  border-radius: 20px;
  padding: 5px;
  max-width: 222px;
  padding-right: 15px;
  margin-top: -8px;

  .c-ich-height {
    height: 15px;
    margin-bottom: 3px;
  }
}

.c-ich-brand {
  margin-left: 10px;
}

.c-button-ich {
  padding: 0;
}

.message {
  background-color: rgba(#00b383, .2);
  border: 1px solid #00b383;
  color: #00b383;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;

  &.error {
    color: #c0392b;
    background-color: rgba(#c0392b, .2);
    border: 1px solid #c0392b;
  }
}

.c-shield-icon {
  margin-left: 5px;
}
</style>
