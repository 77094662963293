<template>
  <div class="c-overflow-hidden">
    <loading v-model:active="isLoading" :can-cancel="true" :is-full-page="fullPage" />
    <template v-if="!isLoading">
      <Header button="Einkaufen"></Header>
      <div class="position-relative mt-150"><router-view></router-view></div>
      <Footer></Footer>
    </template>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { mapStores } from 'pinia';

import type { ConfigType, JwtIdToken, ShopDetails } from './types';

import { useStore } from './stores/store';

import '@/components/style.scss';

import Footer from '@/components/footer/Footer.vue';
import Header from '@/components/header/Header.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,

      config: undefined as unknown as ConfigType
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  async mounted() {
    const isDev = import.meta.env.DEV;
    if (isDev) {
      this.config = await import('@/assets/config.json');
    } else {
      this.config = (await axios.get('/assets/config.json')).data;
    }

    this.shopStore.state.config = this.config;

    const apiHost = this.config.apiHost;
    const instance = this.config.instance;

    const shopDetails: { data: ShopDetails } = await axios.get(apiHost + 'json?ichapp=getShopDetails&version=' + instance);

    let categories = shopDetails.data.categories;
    categories.forEach(c =>
      c.products.forEach(p => {
        let store = this.shopStore.state.products;
        let index = store.findIndex(product => product.uid === p.uid);
        if (index === -1) {
          store.push({ category: c, category_id: c.uid, ...p });
        }
      })
    );
    this.shopStore.state.productCategory = [...shopDetails.data.categories];
    this.shopStore.state.filteredProducts = this.shopStore.state.products;
    Object.assign(this.shopStore.state.shopDetails, shopDetails.data.details);
    document.title = shopDetails.data.details.shopTitle;
    let favicon = document.getElementById('favicon') as HTMLLinkElement | null;
    if (favicon) favicon.href = shopDetails.data.details.favicon;

    if (sessionStorage.products) {
      this.shopStore.state.cartProducts = JSON.parse(sessionStorage.products);
      this.shopStore.caluculateTotalPrice();
    }

    const params = new URLSearchParams(window.location.search);
    let code = params.get('code');

    if (code && code.length > 5 && this.shopStore.state.selectedProfile <= 1) this.$router.push('/formular');
    if (code && code.length > 5 && !this.$cookies.get('ichapp-logged-in')) {
      const userData = await axios.get(`${apiHost}get_id_token?code=${code}&version=${instance}`);
      console.log(userData);
      if (this.$cookies.get('ichapp-last-visited') === 'formular') {
        //go to formular page
        this.$router.push('/formular');
      }
      const token = jwt_decode<JwtIdToken>(userData.data.response.id_token);
      if (this.shopStore.state.selectedProfile < 1) {
        const key = this.shopStore.state.profiles[this.shopStore.state.selectedProfile].fields[0];
        const success = !!key && token[key] === 'true';
        if (!success) this.shopStore.state.ageVerificationFailed = true;
        this.shopStore.state.ageVerified = success;
      }
      if (this.shopStore.state.selectedProfile === 1 && token) {
        this.shopStore.state.ichAuthSuccess = true;
        this.shopStore.state.usersub = token.sub ?? '';
      }
      if (token['urn:ich.app:Address:Data']) {
        token['urn:ich.app:Address:Data'] = JSON.parse(token['urn:ich.app:Address:Data']);
      }
      // @ts-ignore
      this.$cookies.set('ichapp-logged-in', token);
      this.shopStore.state.userData = token;
    }

    if (this.$cookies.get('ichapp-logged-in')) {
      // @ts-ignore
      this.shopStore.state.userData = this.$cookies.get('ichapp-logged-in');
    }

    this.isLoading = false;
  }
};
</script>

<style></style>
