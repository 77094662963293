<template>
  <div class="bg-info mt-40 c-footer">
    <div class="row mx-35">
      <div class="col-12 col-sm-4 col-xl-3 c-footer-adress-abonn-font">
        <h3>ADRESSE</h3>
        <ul class="list-unstyled">
          <li class="text-style-none">PSA Payment Services Austria GmbH</li>
          <li>Handelskai 92, Gate 2, 1200 Wien</li>
          <li class="mt-30">office@psa.at</li>
          <li class="mt-30">
            <router-link class="text-decoration-none text-black" to="/impressum">Impressum</router-link>
          </li>
        </ul>

        <div class="d-flex">
          <a class="me-30" href="#">
            <img src="@/assets/images/facebook.svg" />
          </a>
          <a class="me-30" href="#">
            <img src="@/assets/images/twitter.svg" />
          </a>
          <a class="me-30" href="#">
            <img src="@/assets/images/instagram.svg" />
          </a>
          <a class="me-30" href="#">
            <img src="@/assets/images/youtube.svg" />
          </a>
          <a class="me-30" href="#">
            <img src="@/assets/images/pSocial.svg" />
          </a>
        </div>
      </div>
      <div class="col-6 col-sm-4 col-xl-3 ps-sm-80 ps-md-100 c-footer-shop-hilfe-font">
        <h3>SHOP</h3>
        <ul class="p-0">
          <li class="list-unstyled" v-for="(category, i) in shopStore.state.productCategory" :key="i">
            <router-link class="text-decoration-none link-dark" to="/" @click="arrayFilter(category.uid), removeHero()">
              {{ category.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <div
        class="col-6 col-sm-4 col-xl-3 c-footer-shop-hilfe-font"
        v-for="(navigation, i) in footerNavigation"
        :key="i"
      >
        <h3>{{ navigation.title }}</h3>
        <ul class="p-0">
          <li class="list-unstyled" v-for="(link, i) in navigation.sublinks" :key="i">
            <a :href="link.links">{{ link.name }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-xl-3 c-footer-adress-abonn-font">
        <h3>Abonnieren</h3>
        <p>Seien Sie der Erste, der die neuesten Nachrichten über Trends, Werbeaktionen und vieles mehr erfährt!</p>
        <div class="d-flex align-items-center justify-content-between p-20 bg-white c-mw-600 my-20">
          <p v-if="Object.keys(shopStore.state.userData).length !== 0">
            {{ shopStore.state.userData['urn:ich.app:Mail:Data'] }}
          </p>
          <p v-else>DEINE E-MAIL ADRESSE</p>
          <a class="btn btn-white">ANMELDEN</a>
        </div>
        <div class="row">
          <div class="col-8 col-lg-12 mt-10">
            <strong class="mb-20">Gesicherte Zahlungen</strong>
            <div class="row row-cols-3 row-cols-lg-6 row d-flex align-items-center">
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/eps.png" />
                </a>
              </div>
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/discover.svg" />
                </a>
              </div>
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/mastercard.svg" />
                </a>
              </div>
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/paypal.svg" />
                </a>
              </div>
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/skrill.svg" />
                </a>
              </div>
              <div class="col mt-10">
                <a href="#">
                  <img class="c-w-paymentLogo" src="@/assets/images/visa.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-border-top mt-20 p-20 d-xl-flex justify-content-xl-end mx-35">
      <p>
        <span class="text-secondary me-10">Sprache</span>Deutsch
        <img src="@/assets/images/upload.svg" />
      </p>
      <p class="ms-xl-40">
        <span class="text-secondary me-10">Währung</span> € EUR
        <img src="@/assets/images/upload.svg" />
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { useStore } from '@/stores/store';
import { mapStores } from 'pinia';

export default {
  name: 'Footer',
  data() {
    return {
      footerNavigation: [
        {
          title: 'Hilfe',
          sublinks: [
            {
              name: 'Kundenbetreuung',
              links: '#'
            },
            {
              name: 'Mein Konto',
              links: '#'
            },
            {
              name: 'Geschäft finden',
              links: '#'
            },
            {
              name: 'Rechtliches & Datenschutz',
              links: '#'
            },
            {
              name: 'Kontakt',
              links: '#'
            },
            {
              name: 'Geschenkkarte',
              links: '#'
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    arrayFilter(id: number) {
      this.shopStore.productsFilter(id);
    },
    removeHero() {
      this.shopStore.onFiltered();
    }
  }
};
</script>
<style lang="scss">
.c-border-top {
  border-top: 1px solid #cfcdcd;
}

.c-footer h3 {
  margin-bottom: 35px;
  margin-top: 50px;
  text-transform: uppercase;
}

.c-footer-shop-hilfe-font {
  font-size: 14px;
  line-height: 40px;
}

.c-footer-adress-abonn-font {
  font-size: 14px;
  line-height: 24px;
}

.c-w-paymentLogo {
  width: 50px;
}

.c-mw-600 {
  max-width: 500px;
}
h2 {
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .c-w-paymentLogo {
    width: 35px;
  }
  .c-footer h3 {
    margin-bottom: 20px;
  }
}
</style>
