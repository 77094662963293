<template>
  <div class="c-order">
    <div class="container mb-100 c-order-max-width">
      <div class="text-center mt-20">
        <img src="@/assets/images/check.svg" />
        <h2 class="text-capitalize">Vielen Dank!</h2>
      </div>
      <div class="container my-20">
        <div class="row border-1 p-20 c-border-type">
          <div class="col-6">
            <h4>Bestellungsnummer</h4>
            <strong>13119</strong>
          </div>
          <div class="col-6">
            <h4>Datum</h4>
            <strong id="currentDate"></strong>
          </div>
          <div class="col-6">
            <h4>Total</h4>
            <strong
              >€
              {{ total }}</strong
            >
          </div>
          <div class="col-6">
            <h4>Zahlungsart</h4>
            <strong>Kauf auf Rechnung</strong>
          </div>
        </div>
      </div>
      <div class="p-30 pt-20 border border-dark border-1">
        <h3>BESTELLUNGSÜBERSICHT</h3>
        <div
          class="
            d-flex
            justify-content-between
            pt-20
            pb-10
            border-bottom border-2
          "
        >
          <div>
            <h3>PRODUKT</h3>
          </div>
          <div>
            <h3>ZUSAMMENFASSUNG</h3>
          </div>
        </div>
        <div
          v-for="(product, i) in products"
          :key="i"
          class="d-flex justify-content-between pt-5 text-secondary"
        >
          <div class="d-flex">
            <p>{{ product.quantity }}x</p>
            <h3>{{ product.name }}</h3>
          </div>
          <div>
            <h3>€ {{ (parseFloat(product.price) * product.quantity).toFixed(2) }}</h3>
          </div>
        </div>
        <div
          class="
            d-flex
            justify-content-between
            py-5
            border-bottom border-top border-2
          "
        >
          <div>
            <h3>ZUSAMMENFASSUNG</h3>
          </div>
          <div>
            <h3>€ {{ total }}</h3>
          </div>
        </div>
        <div class="d-flex justify-content-between py-5 border-bottom border-2">
          <div>
            <h3>MwSt.</h3>
          </div>
          <div>
            <h3>
              €
              {{
                mwst
              }}
            </h3>
          </div>
        </div>
        <div class="d-flex justify-content-between py-5 border-bottom border-2">
          <div>
            <h3>TOTAL</h3>
          </div>
          <div>
            <h3>
              € {{ total }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapStores } from 'pinia';

import { useStore } from '@/stores/store';

export default {
  name: "Order",

  data() {
    return {
      deliveryPrice: 10,
      products: {...useStore().state.cartProducts},
      total: useStore().state.totalPrice.valueOf().toFixed(2),
      mwst: (-useStore().state.totalPrice.valueOf() * 1.2 + useStore().state.totalPrice.valueOf()).toFixed(2)
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  mounted() {
    var n = new Date();
    var month = '' + (n.getMonth() + 1);
    var day = '' + n.getDate();
    var year = n.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    (document.getElementById("currentDate") as HTMLElement).innerHTML = [day, month, year].join('/');
    this.shopStore.clearCart();
  }
};
</script>
<style lang="scss">
.c-order .container {
  max-width: 900px;
}

.c-border-type {
  border-style: dashed;
}
</style>
