<template>
  <div class="position-relative c-margin-top">
    <picture >
      <source class="hero-image"
        :srcset="shopDetails.heroImage"
        media="(min-width: 600px)"
      />
      <img class="hero-image" :src="shopDetails.heroImageSmall" />
    </picture>
    <div class="position-absolute hero-title">
      <h1 class="text-white">{{ shopDetails.title }}</h1>
      <a v-if="shopDetails.buttonText !== ''" class="text-decoration-none btn btn-light mt-25 c-max-width" :href="shopDetails.heroHref"
        >{{ shopDetails.buttonText }}</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '@/stores/store';

const shopDetails = useStore().state.shopDetails
</script>

<style lang="scss">
.c-margin-top {
  margin-top: -40px;
}

.c-max-width {
  width: 230px;
}

.text-white {
  font-size: 44px;
}

.hero-title {
  top: 70px;
  transform: translateY(-50px);
  left: 8%;

  a {
    border-radius: 0;
  }
}

.hero-image {
  height: 30vh;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 780px) {
  .hero-title {
    top: 30%;
  }
}

@media (max-width: 780px) {
  .hero-image {
    height: 50vh;
  }
}
</style>
