<template>
  <div>
    <Hero v-show="shopStore.state.toggleComponents && shopStore.state.heroOnFilter" />
    <div v-show="shopStore.state.heroOnFilter" class="text-center pt-20">
      <h2 class="c-not-uppercase">Produkte</h2>
    </div>
    <div v-if="shopStore.state.filteredProducts.length >= 4">
      <carousel
        class="mx-40 mt-20"
        :items-to-show="1"
        :breakpoints="breakpoints"
        :wrapAround="false"
        :key="'product-carousel-' + shopStore.state.activeCategory"
      >
        <slide v-for="(product, i) in shopStore.state.filteredProducts" :key="i">
          <div class="mx-35">
            <div class="mx-10">
              <div class="card">
                <img
                  :src="product.image"
                  class="card-img-top c-image-size p-10 c-product-image"
                  alt="..."
                />
              </div>
              <div class="card-body text-start">
                <div class="d-flex justify-content-between">
                  <h4>{{ product.name }}</h4>
                  <button
                    class="border-0 bg-white"
                    @click="favorit(product.uid)"
                  >
                    <svg
                      width="20"
                      height="16"
                      y="0px"
                      x="0px"
                      viewBox="0 0 471.701 471.701"
                      stroke="#767676"
                    >
                      <path
                        :id="'heart-' + product.uid"
                        fill="#FFFFFF"
                        d="m433.601,68.66767c-24.7,-24.7 -57.4,-38.2 -92.3,-38.2s-67.7,13.6 -92.4,38.3l-12.9,12.9l-13.1,-13.1c-24.7,-24.7 -57.6,-38.4 -92.5,-38.4c-34.8,0 -67.6,13.6 -92.2,38.2c-24.7,24.7 -38.3,57.5 -38.2,92.4c0,34.9 13.7,67.6 38.4,92.3l187.8,187.8c2.6,2.6 6.1,4 9.5,4c3.4,0 6.9,-1.3 9.5,-3.9l188.2,-187.5c24.7,-24.7 38.3,-57.5 38.3,-92.4c0.1,-34.9 -13.4,-67.7 -38.1,-92.4z"
                        stroke="#767676"
                        stroke-width="50"
                      />
                    </svg>
                  </button>
                </div>
                <h3 class="card-title">{{ product.name }}</h3>
                <h3>
                  <p class="card-text">
                    € {{ parseFloat(product.price).toFixed(2) }}
                  </p>
                </h3>
                <button
                  class="
                    btn btn-dark
                    py-16
                    px-20
                    py-md-20
                    px-md-50 px-20
                    md-md-20
                    px-md-md-50
                    mt-30
                    text-uppercase
                    c-shop-button-font
                  "
                  @click="
                    addToCart(product.uid);
                    togglecart();
                  "
                >
                  Hinzufügen
                </button>
              </div>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div
      v-if="shopStore.state.filteredProducts.length < 4"
      class="w-100 mt-20 px-20"
    >
      <div class="row">
        <div
          class="col-12 col-md"
          v-for="(product, i) in shopStore.state.filteredProducts"
          :key="i"
        >
          <div class="card">
            <img
              :src="product.image"
              class="card-img-top c-image-size p-10 c-product-image"
              alt="..."
            />
          </div>
          <div class="card-body text-start">
            <div class="d-flex justify-content-between">
              <h4>{{ product.category.name }}</h4>
              <button class="border-0 bg-white" @click="favorit(product.uid)">
                <svg
                  width="20"
                  height="16"
                  y="0px"
                  x="0px"
                  viewBox="0 0 471.701 471.701"
                  stroke="#767676"
                >
                  <path
                    :id="'heart-' + product.uid"
                    fill="#FFFFFF"
                    d="m433.601,68.66767c-24.7,-24.7 -57.4,-38.2 -92.3,-38.2s-67.7,13.6 -92.4,38.3l-12.9,12.9l-13.1,-13.1c-24.7,-24.7 -57.6,-38.4 -92.5,-38.4c-34.8,0 -67.6,13.6 -92.2,38.2c-24.7,24.7 -38.3,57.5 -38.2,92.4c0,34.9 13.7,67.6 38.4,92.3l187.8,187.8c2.6,2.6 6.1,4 9.5,4c3.4,0 6.9,-1.3 9.5,-3.9l188.2,-187.5c24.7,-24.7 38.3,-57.5 38.3,-92.4c0.1,-34.9 -13.4,-67.7 -38.1,-92.4z"
                    stroke="#767676"
                    stroke-width="50"
                  />
                </svg>
              </button>
            </div>
            <h3 class="card-title">{{ product.name }}</h3>
            <h3>
              <p class="card-text">{{ parseFloat(product.price).toFixed(2) }}€</p>
            </h3>
            <button
              class="
                btn btn-dark
                py-16
                px-20
                py-md-20
                px-md-50
                mt-30
                c-shop-button-font
              "
              @click="
                addToCart(product.uid);
                togglecart();
              "
            >
              ZUM WARENKORB
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapStores } from "pinia";

import { useStore } from "@/stores/store";

import Cart from "@/components/cart/Cart.vue";
import Hero from "@/components/hero/Hero.vue";
import References from "@/components/references/References.vue";

export default {
  name: "Shop",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Cart,
    Hero,
    References,
  },

  data() {
    return {
      breakpoints: {
        800: {
          itemsToShow: 3,
        },

        1200: {
          itemsToShow: 4,
        },
      },
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    addToCart(id: number) {
      let checkvalue = this.shopStore.state.cartProducts.findIndex(
        (x) => x.uid === id
      );
      if (checkvalue === -1) {
        let object = this.shopStore.state.products.find((o) => o.uid === id);
        if (object) this.shopStore.state.cartProducts.push({ quantity: 1, ...object });
      } else {
        let objectcard = this.shopStore.state.cartProducts.find(
          (o) => o.uid === id
        );
        if (objectcard) objectcard.quantity = objectcard.quantity + 1;
      }
      this.shopStore.caluculateTotalPrice();
    },
    togglecart() {
      this.shopStore.openCart();
    },
    favorit(id: number) {
      (document.getElementById("heart-" + id) as HTMLElement).classList.toggle("c-heart");
    },
  },
  watch: {
    "shopStore.state.cartProducts": {
      deep: true,
      handler() {
        this.shopStore.setSessionStorage();
      },
    },
  },
};
</script>
<style lang="scss">
.c-heart {
  fill: red;
}

.c-shop-button-font {
  font-size: 20px;
  line-height: 24px;
}

.c-product-image {
  object-fit: contain;
}

.carousel button {
  background-color: #eeeeee;

  svg {
    color: #767676;
  }
}

.carousel .btn-dark {
  background-color: black;
}

.c-position {
  margin-right: 20px;
}

.c-image-size {
  height: 200px;
  width: 200px;
}

.c-not-uppercase {
  text-transform: none;
}

@media (min-width: 1200px) {
  .c-image-size {
    height: 300px;
    width: 300px
  }
}
</style>
