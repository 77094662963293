<template>
  <div class="container my-50 c-impressum-font">
    <h2 class="mb-50">IMPRESSUM</h2>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-5">
            <strong>Firma</strong>
            <p>PSA Payment Services Austria GmbH</p>
            <strong>Rechtsform</strong>
            <p>Gesellschaft mit beschränkter Haftung</p>
            <strong>Adresse</strong>
            <p>Handelskai 92, Gate 2, 1200 Wien</p>
            <div class="d-flex mt-30">
              <strong>Telefon</strong>
              <p class="ms-20">+431717180</p>
            </div>
            <div class="d-flex">
              <strong>Telefax</strong>
              <p class="ms-20">+43171718900</p>
            </div>
            <div class="d-flex">
              <strong>E-Mail</strong>
              <p class="ms-20">office@psa.at</p>
            </div>
            <div class="d-flex mt-30">
              <strong>Firmensitz</strong>
              <p class="ms-20">Wien</p>
            </div>
            <div class="d-flex">
              <strong>Firmenbuchgericht</strong>
              <p class="ms-20">Handelsgericht Wien</p>
            </div>
            <div class="d-flex">
              <strong>Firmenbuchnummer</strong>
              <p class="ms-20">FN370048p</p>
            </div>
            <div class="d-flex">
              <strong>UID Nummer</strong>
              <p class="ms-20">ATU66782626</p>
            </div>
          </div>
          <div class="col-12 col-md-5 mt-50 mt-md-0">
            <strong>Kammerzugehörigkeit</strong>
            <ol class="ps-0 mb-0 ms-16 w-75">
              <li>
                Wirtschaftskammer Österreich Bundessparte Information und
                Consulting Fachverband Finanzdienstleister
              </li>
              <li>
                Wirtschaftskammer Österreich Fachgruppe Unternehmensberatung,
                Buchhaltung und Informationstechnologie Berufszweig IT
                Dienstleistung
              </li>
            </ol>
              <p class="c-headliner">Zuständige Aufsichtsbehörde</p>
              <p>Finanzmarktaufsicht, Bereich Bankenaufsicht</p>
              <p class="c-headliner">Gewerbebehörde</p>
              <p>Magistratisches Bezirksamt für den 2./20. Bezirk</p>
              <p class="c-headliner">Anwendbare Rechtsvorschriften</p>
              <p>Zahlungsdienstegesetz (ZaDIG), Gewerbeordnung (GewO)</p>
              <strong>und Zugang hierzu</strong>
              <p>www.ris.bka.gv.at</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "Impressum",
};
</script>
<style lang="scss">
  .c-impressum-font {
    font-size: 18px;
    line-height: 28px;
  }
  .c-headliner {
    margin-top: 30px;
    font-weight: bold;
  }
</style>
