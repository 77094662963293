<template>
    <div class="profiles">
      <div class="close">
        <button class="close-button" @click="closeProfilesMenu">x</button>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <h3>Profile</h3>
          <ul>
              <li v-for="(profile, index) in shopStore.state.profiles" :key="profile.profile"><input type="radio" :id="'profile-radio-' + profile.profile" :value="index" v-model="shopStore.state.selectedProfile" /><label :for="profile.profile">{{profile.profile}}</label></li>
          </ul>
        </div>
        <div class="col-12 col-md-4">
          <h3>LoA</h3>
          <ul>
            <li><input type="radio" id="loa-1fa" value="1" v-model="shopStore.state.selectedLoa"/><label for="loa-1fa">1FA</label></li>
            <li><input type="radio" id="loa-2fa" value="0" v-model="shopStore.state.selectedLoa"/><label for="loa-2fa">2FA</label></li>
          </ul>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';

import { useStore } from '@/stores/store';

export default {
  components: {
  },
  name: "Profiles",
  props: ["toggle"],
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    closeProfilesMenu() {
      this.shopStore.state.profilesOpen = false;
    },
    resetFields() {
      this.shopStore.state.ichAuthSuccess = false;
      this.shopStore.state.ageVerified = false;
      this.shopStore.state.ageVerificationFailed = false;
      this.shopStore.state.cartProducts = [];
      this.shopStore.state.totalPrice = 0;
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        sessionStorage.clear();
        this.shopStore.state.cartProducts = [];
        this.shopStore.state.userData = {};
      }
      this.shopStore.setSelectedProfileStorage();
      this.shopStore.setSelectedLoaStorage();
    }
  },
  watch: {
    'shopStore.state.selectedProfile': {
      deep: true,
      handler() {
        this.resetFields();
      }
    },
    'shopStore.state.selectedLoa': {
      deep: true,
      handler() {
        this.resetFields();
      }
    }
  }
};
</script>
<style lang="scss">
.profiles {
  position: fixed;
  right: 20px;
  width: 420px;
  background: #fff;
  padding: 10px 15px;
  box-shadow: 5px 10px 15px 1px rgba(0, 0, 0, 0.2);

  .col-12:nth-child(2) {
    padding-bottom: 120px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100vh;
    top: 110px;
    left: 0;
    right: 0;
    overflow: scroll;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {

      margin: 5px auto;

      label {
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &-button {
      background: none;
      border: none;
    }
  }
}
</style>
