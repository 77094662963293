<template>
  <div class="container">
    <div class="row m-20 m-md-50 justify-content-md-center">
      <div class=" col-xl-8">
        <div class="col-12">
          <h2>Übersicht</h2>
        </div>
        <!-- <div class="col-12 mt-50">
          <div class="row">
            <div class="col-6" v-if="store.state.selectedProfile > 10">
              <h3 class="text-uppercase">Lieferadresse</h3>
              <p class="mt-16">{{ store.state.userData['urn:ich.app:Firstname:Data'] }} {{store.state.userData['urn:ich.app:Lastname:Data'] }}</p>
              <p>{{ store.state.userData['urn:ich.app:Address:Data'].thoroughfare }} {{ store.state.userData['urn:ich.app:Address:Data'].adminUnitL1 }}</p>
              <p>{{ store.state.userData['urn:ich.app:Address:Data'].postCode }}, {{ store.state.userData['urn:ich.app:Address:Data'].postName }}</p>
            </div>
            <div class="col-6">
              <h3 class="text-uppercase">Zahlungsart</h3>
              <p class="mt-16">Kauf auf Rechnung</p>
            </div>
          </div>
        </div> -->
        <div class="col-12 mt-20">
          <p>{{ store.state.userData['urn:ich.app:Mail:Data'] }}</p>
          <p>{{ store.state.userData['urn:ich.app:Phonenumber:Data'] }}</p>
        </div>
        <div class="col-12 mt-35">
          <div class="d-flex align-items-center">
            <input type="checkbox" />
            <p class="ms-20 my-0">Ja, ich akzeptiere hiermit die AGB</p>
          </div>
          <div class="d-flex">
            <input class="mt-5" type="checkbox" />
            <p class="ms-20">
              Ja, ich möchte per E-Mail kostenlos über die neuesten Angebote und
              Aktionen informiert werden.
            </p>
          </div>
          <div class="col-12">
            <router-link
              class="btn btn-dark w-100 my-35 py-20 c-max-width"
              to="/order"
            >
              VORGANG ABSCHLIESSEN
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useStore } from '@/stores/store';

const store = useStore()
</script>

<style lang="scss" scoped>
.c-max-width {
  max-width: 300px;
}
</style>
