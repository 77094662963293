<template>
  <div class="header w-100 bg-white fixed-top zindex-dropdown">
    <div class="row align-items-center justify-content-md-between mx-50">
      <nav class="navbar navbar-expand-md navbar-light c-height">
        <div class="container-fluid p-0">
          <router-link to="/"
            ><img
              class="c-logo-max-heigth"
              :src="shopDetails.logoImage"
              @click="showHero()"
          /></router-link>
          <button
            class="c-navbar c-no-border bg-white d-md-none start-20 collapse"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            id="navbarText"
          >
            <img src="@/assets/images/close.svg" />
          </button>
          <button
            class="navbar-toggler c-navbar"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="c-change-position justify-content-md-end navbar-collapse collapse bg-white bottom-0 start-0 top-0 end-0"
            id="navbarText"
          >
            <ul
              class="navbar-nav pt-md-0 pt-130 mb-2 mb-lg-0 mx-30 mx-md-0 justify-content-md-between align-items-md-center"
            >
              <li
                class="nav-item py-16 py-md-0 ps-md-20 border-2 pe-md-20 c-border-bottom c-display-none"
              >
                <img src="@/assets/images/lupe.svg" />
                <input
                  disabled
                  class="mx-16 p-10 c-search-width c-border-none bg-white"
                  type="text"
                  placeholder="Produkt suchen"
                />
                <button
                  class="c-button-none btn btn-dark py-10 px-20 c-button-none"
                >
                  SUCHEN
                </button>
              </li>
              <li
                v-if="Object.keys(shopStore.state.userData).length === 0"
                class="nav-item py-16 py-md-0 border-2 c-border-bottom"
              >
                <div class="c-ich-border-navigation">
                  <button
                    @click="redirectToIch()"
                    class="border-0 bg-white d-flex align-items-center"
                  >
                    <img
                      class="pe-16 c-ich-height"
                      src="@/assets/images/ich.svg"
                    />
                    <p>{{shopStore.state.selectedProfile === 0 ? 'Alter verifizieren mit ich.' : 'Anmelden mit ich.'}}</p>
                  </button>
                </div>
              </li>
              <li v-if="shopStore.state.ageVerified" class="nav-item py-md-0">
                <div class="d-inline-block ms-lg-20 c-loggin-ichborder age-verification">
                  <button class="border-0 bg-white c-ich-color c-button-ich">
                    <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
                      Alter verifiziert mit ich.
                    <img class="c-shild-heigth c-shield-icon" src="@/assets/images/ich-shield.svg" />
                  </button>
                </div>
              </li>
              <li v-if="shopStore.state.ichAuthSuccess" class="nav-item py-md-0">
                <div class="d-inline-block ms-lg-20 c-loggin-ichborder ich-verification">
                  <button class="border-0 bg-white c-ich-color c-button-ich">
                    <img class="pe-16 c-ich-height c-ich-brand" src="@/assets/images/ich.svg" />
                      Angemeldet mit ich.
                    <img class="c-shild-heigth c-shield-icon" src="@/assets/images/ich-shield.svg" />
                  </button>
                </div>
              </li>
              <li
                class="nav-item py-16 py-xl-0 ps-xl-20 border-2 c-border-bottom d-flex"
              >
                <button
                  class="nav-link d-flex flex-xl-row-reverse bg-white border-0"
                  @click="showLogoutButton()"
                >
                  <div>
                    <img
                      class="pe-20 ps-xl-20"
                      src="@/assets/images/user.svg"
                    />
                  </div>
                  <div
                    v-if="Object.keys(shopStore.state.userData).length !== 0"
                  >
                    {{ shopStore.state.userData["urn:ich.app:Firstname:Data"] }}
                    {{ shopStore.state.userData["urn:ich.app:Lastname:Data"] }}
                  </div>
                  <div v-else>Anmelden</div>
                  <div class="dummy-user" v-if="shopStore.state.selectedProfile === 1 && shopStore.state.ichAuthSuccess">Max Mustermann<div class="user-id">
                    {{shopStore.state.usersub}}
                  </div></div>
                </button>
                <button
                  v-if="showLogout"
                  @click="logout()"
                  class="btn btn-danger"
                >
                  Logout
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="bg-body c-no-border nav-link"
                  @click="togglecart()"
                >
                  <img class="mb-2" src="@/assets/images/shopping-cart.svg" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="d-flex justify-content-between bg-primary align-items-center">
      <div class="d-flex justify-content-around justify-content-start py-2">
        <router-link
          class="text-decoration-none link-dark ms-24 py-5 border-0 bg-primary text-uppercase font-weight-bold"
          to="/"
          v-for="(category, i) in shopStore.state.productCategory"
          :key="i"
          @click="arrayFilter(category.uid), removeHero()"
        >
          {{ category.name }}
        </router-link>
      </div>
      <div class="me-20">
        <button class="profiles-button" @click="toggleProfiles()">
          <img src="@/assets/images/gear.png" />
        </button>
      </div>
      <div class="profile-indicator">
        <span>Profil: {{shopStore.state.profiles[shopStore.state.selectedProfile].profile}}, LoA: {{ shopStore.state.selectedLoa == 0 ? '2FA' : '1FA' }}</span>
      </div>
    </div>
    <Cart v-show="shopStore.state.cartOpen" :toggle="togglecart" />
    <Profiles v-show="shopStore.state.profilesOpen" :toggle="toggleProfiles" />
  </div>
</template>

<script lang="ts">
import { mapStores } from "pinia";

import { router } from "@/router";
import { useStore } from "@/stores/store";

import Cart from "@/components/cart/Cart.vue";
import Profiles from "@/components/profiles/Profiles.vue";

export default {
  name: "Header",
  components: {
    Cart,
    Profiles
  },
  data() {
    return {
      shopDetails: useStore().state.shopDetails,
      showLogout: false,
    };
  },
  computed: {
    ...mapStores(useStore)
  },
  methods: {
    togglecart() {
      if (this.shopStore.state.cartOpen) {
        this.shopStore.closeCart();
      } else {
        this.shopStore.openCart();
      }
    },
    toggleProfiles() {
      if (this.shopStore.state.profilesOpen) {
        this.shopStore.closeProfiles();
      } else {
        this.shopStore.openProfiles();
      }
    },
    arrayFilter(id: number) {
      this.shopStore.productsFilter(id);
    },
    removeHero() {
      this.shopStore.onFiltered();
    },
    showHero() {
      this.shopStore.allProducts();
      this.shopStore.onNotFiltered();
    },
    redirectToIch() {
      const instance = this.shopStore.state.config.instance;
      const apiHost = this.shopStore.state.config.apiHost;
      const currentLocation = window.location.hash === '#/' ? 'home' : 'formular';
      this.$cookies.set('ichapp-last-visited', currentLocation);
      let url = apiHost + 'auth_get_token';
      let profileIndex = this.shopStore.state.selectedProfile
      let loa = this.shopStore.state.selectedLoa;
      url += Number.isInteger(profileIndex) ? '?profile=' + this.shopStore.state.profiles[profileIndex].profile : '';
      url += Number.isInteger(parseInt(loa)) ? '&loa=' + loa : ''
      url += '&version=' + instance;
      location.href = url;
    },
    showLogoutButton() {
      if (Object.keys(this.shopStore.state.userData).length !== 0) {
        this.showLogout = !this.showLogout;
      } else {
        this.redirectToIch();
      }
    },
    logout() {
      this.showLogout = false;
      this.shopStore.state.ichAuthSuccess = false;
      this.shopStore.state.ageVerified = false;
      this.shopStore.state.ageVerificationFailed = false;
      this.shopStore.state.selectedProfile = '5';
      this.shopStore.state.selectedLoa = '0';
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        sessionStorage.clear();
        this.shopStore.state.cartProducts = [];
        this.shopStore.state.userData = {};
        router.push('/');
      }
    },
  },
};
</script>
<style lang="scss">
.c-height {
  height: 72px;
}

.c-display-none .c-button-none {
  display: none;
}

.c-search-width {
  width: 230px;
}

.c-ich-height {
  height: 15px;
  margin-bottom: 3px;
}

.c-logo-max-heigth {
  max-width: 235px;
  max-height: 50px;
}

.c-border-none {
  border: none;
}

.c-border-bottom {
  border-bottom: solid #dee2ed;
}

.c-navbar {
  z-index: 3;
  left: 20px;
}

.navbar-collapse {
  z-index: 1;
}

.c-no-border {
  border: none;
}

.navbar-light .navbar-toggler {
  background-color: #33ad00;
  border-radius: 0;
}

#navbarText {
  position: fixed;
}

.age-verification {
  margin-top: 2px;
}

.profile-indicator {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
}

.profiles-button {
  border: none;
  padding: 5px;
  background: none;
  border-radius: 20px;
}

.dummy-user {
  position: relative;

  &:hover .user-id {
    display: block;
  }

  .user-id {
    display: none;
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 0;
    top: 30px;
    width: 200px;
    overflow-wrap: break-word;
    text-align: left;
    padding: 5px 10px;
  }
}

@media (min-width: 768px) {
  .c-display-none {
    display: none;
  }

  .c-border-none {
    border: solid 1px;
  }

  .c-border-bottom {
    border: none;
  }

  .c-cart-icon {
    display: block;
  }

  #navbarText {
    position: relative;
  }

  .c-ich-border-navigation {
    border: solid #51f0b0;
    border-radius: 20px;
    padding: 5px;
    padding-right: 15px;
  }
}

@media (min-width: 1250px) {
  .c-display-none {
    display: block;

    .c-button-none {
      display: inline-block;
    }
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
