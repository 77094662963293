import Shop from "@/components/shop/Shop.vue";
import Order from "@/components/order/Order.vue";
import Invoice from "@/components/invoice/Invoice.vue";
import Formular from "@/components/formular/Formular.vue";
import Impressum from "@/components/impressum/Impressum.vue";
import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/stores/store";

const routes = [
    { path: '/', component: Shop },
    { path: '/impressum', component: Impressum },
    { path: '/invoice', component: Invoice,
        beforeEnter: () => {
            if(Object.keys(useStore().state.userData).length === 0){
                alert('Bitte Melden Sie sich an');
                return false;
            }
        }
    },
    { path: '/order', component: Order, 
        beforeEnter: () => {
            if(Object.keys(useStore().state.userData).length === 0){
                alert('Bitte Melden Sie sich an');
                return false;
            }
        }
    },
    { path: '/formular', component: Formular }
]

export const router = createRouter({
    history: import.meta.env.DEV ? createWebHistory(import.meta.env.BASE_URL) : createWebHistory(),
    routes, // short for `routes: routes`
})
