
import "bootstrap";
import { createPinia } from "pinia";
import { createApp } from "vue";
import VueCookies from 'vue3-cookies';
import type { VueCookies as IVueCookies } from "vue3-cookies/dist/interfaces";

import App from './App.vue';
import { router } from "./router";

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
      $cookies: IVueCookies;
  }
}

const pinia = createPinia()
const app = createApp(App)
app.use(router)
app.use(pinia)
app.use(VueCookies)
app.mount('#app')
